import React, { useState, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { useContext } from '~/hooks'
import { useMediaQuery } from 'react-responsive'
import { Head, Hero, Button, Footer } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'
import Banner from '~/assets/img/Pagina-nao-encontrada.jpg'

const NotFound = ({ location }: PageProps) => {
  const mobile = !useMediaQuery({
    query: '(min-width: 1001px)',
  })

  const { setLoading } = useContext()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setLoading(false)
    setReady(true)
  }, [])

  return ready ? (
    <>
      <Head
        location={location}
        title={'Página não encontrada - ' + process.env.GATSBY_SITE_NAME}
      />
      <Hero
        title="Página não encontrada"
        description={
          'Erro 404. A página que você tentou acessar não existe ou está fora do ar.'
        }
        image={Banner}
        type="notFound"
      >
        <div className={st.btns}>
          <Button href="/" className={st.btn}>
            {mobile ? 'Home' : 'Ir para a home'}
          </Button>
          <Button href="/empreendimentos/" className={st.btn}>
            {mobile ? 'Empreendimentos' : 'Conheça nossos empreendimentos'}
          </Button>
        </div>
      </Hero>
      <Footer />
    </>
  ) : (
    <Head location={location} />
  )
}

export default NotFound
